
import Dashboard from '../main/Dashboard';
import Pipelines from './Pipelines';

export default function AdminPreliminaryReview () {
  return (
    <>
      <Dashboard sx={{ mb: 2 }} />
      <Pipelines />
    </>
  );
}
