import { PipelineCollection } from 'common/pipelines/usePipelineCollection';
import PipelineView from './PipelineView';
import ProgressBar from 'common/ui/ProgressBar';

export default function PipelinesSubPage(props) {
  return (
    <PipelineCollection>
      <PipelineCollection.Config
        id="currentOrder"
        name="Current Order"
z                          source="preliminaryCurrentOrder"
        // link="/admin/order/{{id}}"
        columns={[
          {
            target : 'date.ordered',
            caption : 'Ordered Date',
            size: '10%',
            format: 'Date',
          },
          {
          target:null,
          caption:'Analyst',
          size:'20%',
          format:'orderAnalyst'
          },
          {
            target : 'property.fulladdress',
            size : '30%'
          },
          {
            target: 'product.name',
            caption: 'Order Type',
            size: '20%',
          },
          {
            target: 'date.due',
            caption: 'Due Date',
            size: '10%',
            format: 'Date',
          },
          {
           target: null,
            caption: '',
            size: '10%',
            format:'orderButton',
          }
        ]}
      />
<PipelineCollection.Config
        id="opertionalreview"
        name="Opertional Review"
        source="lightCycleFinal"
        // link="/admin/order/{{id}}"
        columns={[
          {
            target : 'date.ordered',
            caption : 'Ordered Date',
            size: '20%',
            format: 'Date',
            className : 'datecolumn'
          },
          {
            target : 'property.fulladdress',
            size : '30%'
          },
          {
            target: null,
            caption: 'Order Type',
            size: '20%',
          },
          {
            target: 'date.due',
            caption: 'Due Date',
            size: '10%',
            format: 'Date',
            className : 'datecolumn'
          },
          {
           target: null,
            caption: '',
            size: '20%',
            format:'orderButton',
          }
        ]}
      />
      <PipelineView {...props} />
    </PipelineCollection>
  );
}
